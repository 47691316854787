var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4 m-auto"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.reset()}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"code"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.email.$error,
                            },attrs:{"type":"email","id":"email","placeholder":"Enter email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.submitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.submitted && !_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" New Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.confirmPassword.$error,
                            },attrs:{"type":"password","id":"confirmPassword","placeholder":"Enter confirm password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),(_vm.submitted && !_vm.$v.confirmPassword.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Confirm Password is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"code"}},[_vm._v("OTP Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otp),expression:"otp"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.otp.$error,
                            },attrs:{"type":"text","id":"otp","placeholder":"Enter OTP code"},domProps:{"value":(_vm.otp)},on:{"input":function($event){if($event.target.composing)return;_vm.otp=$event.target.value}}}),(_vm.submitted && _vm.$v.otp.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.otp.required)?_c('span',[_vm._v("OTP code is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[(_vm.submitted == true)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Reset ")])]),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":"/login"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Go to login ? ")])],1)])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../../../assets/xefe.png"),"height":"170","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Reset Password Here!")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Enter OTP code and new password. ")])])
}]

export { render, staticRenderFns }