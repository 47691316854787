<script>
import { required, minLength } from "vuelidate/lib/validators";
import swal from "sweetalert";
export default {
  data() {
    return {
      submitted: false,
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
    };
  },

  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required },
    password: { required, minLength: minLength(6) },
    confirmPassword: { required, minLength: minLength(6) },
    otp: { required },
  },
  methods: {
    reset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/auth/update-password`;
        this.$http
          .post(url, {
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
            otp: this.otp,
            authorizationType: "agent-authentication",
          })
          .then((res) => {// eslint-disable-line no-unused-vars
            swal("Successful!", "You can login now, Click ok!", "success").then(
              (res) => {// eslint-disable-line no-unused-vars
                this.$router.push("/login");
              }
            );
          })
          .catch((err) => {// eslint-disable-line no-unused-vars
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4 m-auto">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="../../../assets/xefe.png"
                              height="170"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password Here!</h4>
                        <p class="text-muted">
                          Enter OTP code and new password.
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="reset()">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="code">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">New Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              New Password is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Confirm Password</label>
                            <input
                              v-model="confirmPassword"
                              type="password"
                              class="form-control"
                              id="confirmPassword"
                              placeholder="Enter confirm password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.confirmPassword.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.confirmPassword.required"
                              class="invalid-feedback"
                            >
                              Confirm Password is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="code">OTP Code</label>
                            <input
                              type="text"
                              v-model="otp"
                              class="form-control"
                              id="otp"
                              placeholder="Enter OTP code"
                              :class="{
                                'is-invalid': submitted && $v.otp.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.otp.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.otp.required"
                                >OTP code is required.</span
                              >
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                v-if="submitted == true"
                              ></span>
                              Reset
                            </button>
                          </div>
                          <div class="mt-4 text-center">
                            <router-link to="/login" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Go to login ?
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
